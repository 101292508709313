import { useAuth0 } from '@auth0/auth0-react'
import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import Div100vh from 'react-div-100vh'
import { css } from 'styled-components'
import SwiperCore, { EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ButtonFull from 'components/button-full'
import SigninLinkView from 'components/signin-link'
import { SelfAssessmentRoute, CreateAccountRoute, RedirectRoute } from 'config/routes'
import { useFormatMessage } from 'localization'
import { Colors } from 'styles/colors'
import 'swiper/swiper.min.css'
import { navigate } from 'utilities/react-router-hooks'
import OnBoardPic1 from '../../assets/onboard1new.png'
import OnBoardPic2 from '../../assets/onboard2new.png'
import OnBoardPic3 from '../../assets/onboard3new.png'

SwiperCore.use([EffectCoverflow])

const OnboardingPageView: React.FC = () => {
  const f = useFormatMessage()
  const arrText = [
    {
      title: `${f('WELCOME_TO')} Vocalise`,
      description: f('ON_BOARDING_DESCRIPTION_1'),
      image: OnBoardPic1,
      imgHeight: '80%',
      imgMarginTop: '10px',
    },
    {
      title: f('ON_BOARDING_TITLE_2'),
      description: f('ON_BOARDING_DESCRIPTION_2'),
      image: OnBoardPic2,
      imgHeight: '70%',
      imgMarginTop: '35px',
    },
    {
      title: f('ON_BOARDING_TITLE_3'),
      description: f('ON_BOARDING_DESCRIPTION_3'),
      image: OnBoardPic3,
      imgHeight: '50%',
      imgMarginTop: '0',
    },
  ]

  const [currentIndex, setCurrentIndex] = React.useState(0)
  const { loginWithRedirect } = useAuth0()

  return (
    <Div100vh
      css={css`
        display: flex;
        flex-direction: column;
        background-color: ${Colors.White};
        overflow-y: auto;
      `}>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          overflow-y: auto;
        `}>
        <Box
          css={css`
            display: flex;
            align-items: center;
          `}>
          <Swiper
            spaceBetween={15}
            slidesPerView={1}
            navigation
            onSwiper={(e) => setCurrentIndex(e.activeIndex)}
            onSlideChange={(e) => setCurrentIndex(e.activeIndex)}>
            {arrText.map((slider, index) => (
              <SwiperSlide key={index} style={{ height: 'initial' }}>
                <Box
                  css={css`
                    padding: 0 5%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow-y: auto;
                  `}>
                  <Box
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}>
                    <Box
                      css={css`
                        width: 180px;
                        height: 180px;
                        border-radius: 50%;
                        justify-content: center;
                        display: flex;
                      `}>
                      <img
                        alt={slider.title}
                        css={css`
                          height: 100%;
                          align-self: center;
                        `}
                        src={slider.image}
                      />
                    </Box>
                  </Box>
                  <Typography
                    align="center"
                    css={css`
                      font-family: 'Lora';
                      font-weight: 400;
                      font-size: 1rem;
                      margin-top: 8%;
                      margin-bottom: 4%;
                    `}>
                    <span
                      css={css`
                        font-weight: 700;
                      `}>
                      {slider.title}
                    </span>
                    {'. '}
                    {slider.description}
                  </Typography>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box
          css={css`
            display: flex;
            justify-content: center;
          `}>
          {arrText.map((_: any, index: number) => (
            <Box
              key={index}
              css={css`
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 6px;
                background-color: ${currentIndex === index ? Colors.Amethyst : Colors.SwissCoffe};
              `}
            />
          ))}
        </Box>
      </Box>
      <Box
        css={css`
          display: flex;
          flex-direction: column;
        `}>
        <Box
          css={css`
            padding: 0px 20px;
            margin-bottom: 5%;
          `}>
          <ButtonFull
            title={f('PURCHASE_VOCALISE')}
            buttonColor={Colors.Amethyst}
            titleColor={Colors.WhiteSmoke}
            height={'48px'}
            clickAction={() => {
              navigate(
                process.env.REACT_APP_USE_SELF_ASSESSMENT === 'true'
                  ? SelfAssessmentRoute.generatePath()
                  : CreateAccountRoute.generatePath()
              )
            }}
          />
        </Box>
        <Box
          css={css`
            margin-bottom: 5%;
          `}>
          <SigninLinkView
            clickHandler={() => {
              loginWithRedirect({
                prompt: 'login',
                show_hint: 'login',
                redirectUri: `${window.location.origin}${RedirectRoute.generatePath()}`,
              })
            }}
            textQuestion={f('ALREADY_MEMBER')}
            textQuestionColor={Colors.CloudBurst}
            textLink={f('SIGN_IN_HERE')}
            textLinkColor={Colors.Amethyst}
            textLinkWeight={'700'}
          />
        </Box>
      </Box>
    </Div100vh>
  )
}

export default OnboardingPageView
