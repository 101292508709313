import { Typography, Box, Link } from '@material-ui/core'
import * as React from 'react'
import { css } from 'styled-components'

const SigninLinkView: React.FC<{
  textQuestion: string
  textQuestionColor?: string
  textQuestionWeight?: string
  textLink: string
  textLinkColor?: string
  textLinkWeight?: string
  clickHandler?: () => void | undefined
}> = ({
  textQuestion,
  textQuestionColor,
  textQuestionWeight,
  textLink,
  textLinkColor,
  textLinkWeight,
  clickHandler,
}) => {
  return (
    <Box
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-family: 'Open Sans';
        font-size: 0.8rem;
      `}>
      <Typography
        css={css`
          margin-right: 2px;
          font-weight: ${textQuestionWeight ? textQuestionWeight : '400'};
          color: ${textQuestionColor ? textQuestionColor : 'rgba(0, 0, 0, 0.82)'};
        `}>
        {textQuestion}
      </Typography>
      <Typography>
        <Link
          onClick={clickHandler ? clickHandler : () => {}}
          css={css`
            font-weight: ${textLinkWeight ? textLinkWeight : '400'};
            text-decoration: none;
            color: ${textLinkColor ? textLinkColor : 'rgba(0, 0, 0, 0.82)'};
            cursor: pointer;
          `}>
          {textLink}
        </Link>
      </Typography>
    </Box>
  )
}

export default SigninLinkView
